<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Konfig",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Konfig",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Konfig",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,
            // variable Page
            master_role: ['Role 1', 'Role 2', 'Role 3'],
            role_selected: '',
            konfig: [],
            LOGO: '',
            LOGIN_BACKGROUND: '',
            TITLE_APPLICATION: '',
            APPLICATION: '',
            VERSION: '',
            COPYRIGHT: '',
            TERM_CONDITION: '',
            CATATAN_CV: '',
            slug_data: []
        };
    },
    mounted() {
        this.getKonfig();
    },
    methods: {
        getKonfig() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });

            let self = this;
            axios
                .get(
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "/api/master/konfig"
                )
                .then((response) => {
                    var response_data = response.data;
                    var response_logo = response_data.list_data.data[0];
                    var response_login_background = response_data.list_data.data[1];
                    var response_title_application = response_data.list_data.data[2];
                    var response_application = response_data.list_data.data[3];
                    var response_version = response_data.list_data.data[4];
                    var response_copyright = response_data.list_data.data[5];
                    var response_term = response_data.list_data.data[6];
                    var response_catatan_cv = response_data.list_data.data[7];
                    self.slug_data = response_data.list_slug;
                    if (response_data.code == 200) {
                        self.LOGO = response_logo.value;
                        self.LOGIN_BACKGROUND = response_login_background.value;
                        self.TITLE_APPLICATION = response_title_application.value;
                        self.APPLICATION = response_application.value;
                        self.VERSION = response_version.value;
                        self.COPYRIGHT = response_copyright.value;
                        self.TERM_CONDITION = response_term.value;
                        self.CATATAN_CV = response_catatan_cv.value;
                        $("#uploadLogoLoading").html(
                            '<a href="' +
                            response_logo.value +
                            '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
                        );
                        $("#uploadLoginBackgroundLoading").html(
                            '<a href="' +
                            response_login_background.value +
                            '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
                        );
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.message,
                        });
                    }
                    Swal.close();
                });
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var FormData = require("form-data");
            var data = new FormData();
            data.append("LOGO", self.LOGO);
            data.append("LOGIN_BACKGROUND", self.LOGIN_BACKGROUND);
            data.append("TITLE_APPLICATION", self.TITLE_APPLICATION);
            data.append("APPLICATION", self.APPLICATION);
            data.append("VERSION", self.VERSION);
            data.append("COPYRIGHT", self.COPYRIGHT);
            data.append("TERM_CONDITION", self.TERM_CONDITION);
            data.append("CATATAN_CV", self.CATATAN_CV);
            data.append("slug", self.slug_data);

            var config = {
                method: "post",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/konfig/update",
                data: data,
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    if (response_data.code != 200) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            html: response_data.message,
                        });
                    } else {
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman konfig segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                this.getKonfig();
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
        uploadFileLogo() {
            let self = this;
            if ($("#uploadLogo")[0].files[0]) {
                if ($("#uploadLogo")[0].files[0].size < 5000000) {
                    $("#uploadLogoLoading").html(
                        '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
                    );
                    var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#uploadLogo")[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "api/master/uploadhandle/uploadsfile",
                        headers: {
                            Accept: "application/json",
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        var dir_name = response.data.dir_name;
                        // var file_name = response.data.file_name;
                        urlres += dir_name;
                        $("#uploadLogoLoading").html(
                            '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                            urlres +
                            '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
                        );
                        self.LOGO = urlres;
                    }).catch((e) => {
                        Swal.fire({
                            icon: "error",
                            title: "Gagal upload file",
                            text: e.response?.data?.message?.file,
                        });
                    });
                }
            }
        },
        uploadFileBackground() {
            let self = this;
            if ($("#uploadBackground")[0].files[0]) {
                if ($("#uploadBackground")[0].files[0].size < 5000000) {
                    $("#uploadLoginBackgroundLoading").html(
                        '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
                    );
                    var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#uploadBackground")[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "api/master/uploadhandle/uploadsfile",
                        headers: {
                            Accept: "application/json",
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        var dir_name = response.data.dir_name;
                        // var file_name = response.data.file_name;
                        urlres += dir_name;
                        $("#uploadLoginBackgroundLoading").html(
                            '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                            urlres +
                            '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
                        );

                        self.LOGIN_BACKGROUND = urlres;
                    }).catch((e) => {
                        Swal.fire({
                            icon: "error",
                            title: "Gagal upload file",
                            text: e.response?.data?.message?.file,
                        });
                    });
                }
            }
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-8">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <b-form-group class="mb-3" label="Logo">
                                                <input type="file" id="uploadLogo" accept="image/jpeg, image/png"
                                                    @change="uploadFileLogo">
                                                <input type="hidden" class="form-control" v-model="LOGO">
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="text-end" id="uploadLogoLoading">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <b-form-group class="mb-3" label="Login Background">
                                                <input type="file" accept="image/jpeg, image/png" id="uploadBackground"
                                                    @change="uploadFileBackground">
                                                <input type="hidden" class="form-control" v-model="LOGIN_BACKGROUND">
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="text-end" id="uploadLoginBackgroundLoading">
                                            </div>
                                        </div>
                                    </div>
                                    <b-form-group class="mb-3" label="Title Application">
                                        <b-form-input type="text" v-model="TITLE_APPLICATION"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Application">
                                        <b-form-input type="text" v-model="APPLICATION"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Version">
                                        <b-form-input type="text" v-model="VERSION"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Copyright">
                                        <b-form-input type="text" v-model="COPYRIGHT"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Term Condition">
                                        <textarea v-model="TERM_CONDITION" class="form-control" id="" cols="30"
                                            rows="10"></textarea>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Catatan CV">
                                        <textarea v-model="CATATAN_CV" class="form-control" id="" cols="30"
                                            rows="10"></textarea>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i>
                                Simpan</b-button>
                            <b-button type="reset" variant="danger" class="m-1"><i class="fa fa-refresh"></i>
                                Reset</b-button>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
